<script>
import Options from "@/conf/Options";

// Definition
const sidebarMenu = [];

// const dashboard = {
//   path: "/dashboard",
//   icon: "fas fa-th-large",
//   title: "Dashboard",
//   children: [
//     {
//       path: "/dashboard/statusBranchDistribution",
//       title: "Statuses / Branches"
//     },
//     {
//       path: "/dashboard/highSeasonOrdersDistribution",
//       title: "High Season Orders Distribution"
//     }
//   ]
// };

const orderManagement = {
  path: "/orderManagement",
  icon: "fas fa-shopping-cart",
  title: "Order Management",
  children: [
    {
      path: "/orderManagement/orders",
      title: "Orders"
    }
  ]
};

const orderManagementWithPurchaseOrder = {
  path: "/orderManagement",
  icon: "fas fa-shopping-cart",
  title: "Order Management",
  children: [
    {
      path: "/orderManagement/orders",
      title: "Orders"
    },
    {
      path: "/orderManagement/purchaseOrder",
      title: "Purchase Order"
    }
  ]
};

const userManagement = {
  path: "/userManagement",
  icon: "fas fa-user",
  title: "User Management",
  children: [{ path: "/userManagement/users", title: "Users" }]
};

const customerManagement = {
  path: "/customerManagement",
  icon: "fas fa-users",
  title: "Customer Management",
  children: [{ path: "/customerManagement/customers", title: "Customers" }]
};

const highSeason = {
  path: "/highSeason",
  icon: "fas fa-chart-line",
  title: "High Season",
  children: [
    { path: "/highSeason/dashboard", title: "Dashboard" },
    { path: "/highSeason/seasons", title: "Configuration" },
    { path: "/highSeason/productionFridges", title: "Production Fridges" },
    { path: "/highSeason/wrappingArea", title: "Wrapping Area" },
    { path: "/highSeason/dispatchingFridges", title: "Dispatching Fridges" },
    { path: "/highSeason/transitFridges", title: "Transit Fridges" },
    { path: "/highSeason/ordersDelivery", title: "Orders Delivery" },
    {
      path: "/highSeason/ordersDeliveryStatus",
      title: "Orders Delivery Status"
    }
  ]
};

const highSeasonRoleAdmin = {
  path: "/highSeason",
  icon: "fas fa-chart-line",
  title: "High Season",
  children: [
    { path: "/highSeason/dashboard", title: "Dashboard" },
    { path: "/highSeason/productionFridges", title: "Production Fridges" },
    { path: "/highSeason/wrappingArea", title: "Wrapping Area" },
    { path: "/highSeason/dispatchingFridges", title: "Dispatching Fridges" },
    { path: "/highSeason/transitFridges", title: "Transit Fridges" },
    { path: "/highSeason/ordersDelivery", title: "Orders Delivery" },
    {
      path: "/highSeason/ordersDeliveryStatus",
      title: "Orders Delivery Status"
    }
  ]
};

const highSeasonRoleProductionWorker = {
  path: "/highSeason",
  icon: "fas fa-chart-line",
  title: "High Season",
  children: [
    { path: "/highSeason/productionFridges", title: "Production Fridges" }
  ]
};

const highSeasonRoleDispatcher = {
  path: "/highSeason",
  icon: "fas fa-chart-line",
  title: "High Season",
  children: [
    { path: "/highSeason/wrappingArea", title: "Wrapping Areas" },
    { path: "/highSeason/dispatchingFridges", title: "Dispatching Fridges" },
    { path: "/highSeason/transitFridges", title: "Transit Fridges" }
  ]
};

const highSeasonRoleProductionWrappingDispatching = {
  path: "/highSeason",
  icon: "fas fa-chart-line",
  title: "High Season",
  children: [
    { path: "/highSeason/productionFridges", title: "Production Fridges" },
    { path: "/highSeason/wrappingArea", title: "Wrapping Areas" },
    { path: "/highSeason/dispatchingFridges", title: "Dispatching Fridges" },
    { path: "/highSeason/transitFridges", title: "Transit Fridges" }
  ]
};

const highSeasonRoleDeliveryManager = {
  path: "/highSeason",
  icon: "fas fa-chart-line",
  title: "High Season",
  children: [
    { path: "/highSeason/ordersDelivery", title: "Orders Delivery" },
    {
      path: "/highSeason/ordersDeliveryStatus",
      title: "Orders Delivery Status"
    }
  ]
};

const fridgeManagement = {
  path: "/fridgeManagement",
  icon: "fas fa-th",
  title: "Fridge Management",
  children: [
    { path: "/fridgeManagement/fridge", title: "Fridges" },
    { path: "/fridgeManagement/productionFridge", title: "Production Fridges" }
  ]
};

const wrappingAreaManagement = {
  path: "/wrappingAreaManagement",
  icon: "fas fa-cube",
  title: "Wrapping Area Mgmt",
  children: [
    { path: "/wrappingAreaManagement/wrappingArea", title: "Wrapping Areas" }
  ]
};

const driverManagement = {
  path: "/driverManagement",
  icon: "fas fa-car",
  title: "Driver Management",
  children: [
    {
      path: "/driverManagement/deliveryCompanies",
      title: "Delivery Companies"
    },
    { path: "/driverManagement/drivers", title: "Drivers" }
  ]
};

const reports = {
  path: "/report",
  icon: "fas fa-chart-pie",
  title: "Reports",
  children: [
    {
      path: "/report/sales",
      title: "Sales",
      children: [{ path: "/report/sales/product", title: "Sales by product" }]
    },
    {
      path: "/report/order",
      title: "Orders",
      children: [
        { path: "/report/order/order", title: "Orders" },
        { path: "/report/order/delivery", title: "Delivery by region" },
        { path: "/report/order/driver", title: "Delivery by driver" },
        { path: "/report/order/deliveryCost", title: "Delivery cost" }
      ]
    },
    {
      path: "/report/customer",
      title: "Customer",
      children: [
        { path: "/report/customer/customer", title: "Customers" },
        { path: "/report/customer/order", title: "Orders" }
      ]
    }
  ]
};

const administration = {
  path: "/administration",
  icon: "fas fa-tasks",
  title: "Administration",
  children: [
    {
      path: "/administration/productChecker",
      title: "Product Checker",
      children: [
        { path: "/administration/productChecker/products", title: "Products" },
        { path: "/administration/productChecker/activityLogs", title: "Activity Logs" }
      ]
    }
  ]
};

const administrationPriceCheckerManager = {
  path: "/administration",
  icon: "fas fa-tasks",
  title: "Administration",
  children: [
    {
      path: "/administration/productChecker",
      title: "Product Checker",
      children: [
        { path: "/administration/productChecker/products", title: "Products" }
      ]
    }
  ]
};

// Read only
if (Options.user.roleId == 14) {
  sidebarMenu.push(orderManagement);
}

// Super Admin
if (Options.user.roleId == 1) {
  // sidebarMenu.push(dashboard);
  sidebarMenu.push(orderManagementWithPurchaseOrder);
  sidebarMenu.push(customerManagement);
  sidebarMenu.push(userManagement);
  sidebarMenu.push(highSeason);
  sidebarMenu.push(fridgeManagement);
  sidebarMenu.push(wrappingAreaManagement);
  sidebarMenu.push(driverManagement);
  sidebarMenu.push(reports);
  sidebarMenu.push(administration);
}

// Call Center
if (Options.user.roleId == 2) {
  if (Options.user.storeId == 1) {
    // Lebanon store
    sidebarMenu.push(orderManagementWithPurchaseOrder);
    sidebarMenu.push(customerManagement);
  } else sidebarMenu.push(orderManagement);
}

// Store Manager
if (Options.user.roleId == 3) {
  if (Options.user.storeId == 1) {
    // Lebanon store
    sidebarMenu.push(orderManagementWithPurchaseOrder);
    sidebarMenu.push(customerManagement);
  } else sidebarMenu.push(orderManagement);
}

// Administrator
if (Options.user.roleId == 4) {
  if (Options.user.storeId == 1) {
    // Lebanon store
    sidebarMenu.push(orderManagementWithPurchaseOrder);
    sidebarMenu.push(customerManagement);
    sidebarMenu.push(highSeasonRoleAdmin);
  } else sidebarMenu.push(orderManagement);
}

// Production Worker
if (Options.user.roleId == 5) {
  sidebarMenu.push(highSeasonRoleProductionWorker);
}

// Dispatcher
if (Options.user.roleId == 6) {
  sidebarMenu.push(highSeasonRoleDispatcher);
}

// Delivery Manager
if (Options.user.roleId == 7) {
  sidebarMenu.push(orderManagement);
  sidebarMenu.push(highSeasonRoleDeliveryManager);
}

// Production Wrapping Dispatching
if (Options.user.roleId == 8) {
  sidebarMenu.push(highSeasonRoleProductionWrappingDispatching);
}

// Florist
if (Options.user.roleId == 9) {
  sidebarMenu.push(orderManagement);
}

// Driver Manager
if (Options.user.roleId == 10) {
  sidebarMenu.push(orderManagement);
}

// Accounting
if (Options.user.roleId == 11) {
  sidebarMenu.push(orderManagement);
  sidebarMenu.push(reports);
}

// Advanced Admin
if (Options.user.roleId == 12) {
  if (Options.user.storeId == 1) {
    // Lebanon store
    sidebarMenu.push(orderManagementWithPurchaseOrder);
    sidebarMenu.push(customerManagement);
    sidebarMenu.push(highSeasonRoleAdmin);
    sidebarMenu.push(reports);
  } else sidebarMenu.push(orderManagement);
}

// Price Checker Manager
if (Options.user.roleId == 13) {
  if (Options.user.storeId == 1) {
    // Lebanon store
    sidebarMenu.push(administrationPriceCheckerManager);
  }
}

export default sidebarMenu;
</script>
